@media screen and (min-width:600px) {
    /* HTML */
    html {
        font-size: 106%;
    }
    body {
        background-image: url("data:image/svg+xml,%3Csvg width='0.6em' height='0.3em' viewBox='0 0 52 26' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%230031ff' fill-opacity='1'%3E%3Cpath d='M10 10c0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6h2c0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4v2c-3.314 0-6-2.686-6-6 0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6zm25.464-1.95l8.486 8.486-1.414 1.414-8.486-8.486 1.414-1.414z' /%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
        background-attachment: fixed;
    }
    /* MAIN */
    .main {
        margin-right: 2em;
        margin-left: 2em;
    }
    .container {
        max-width: 72em;
    }


}